import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "opera" }
const _hoisted_3 = { class: "footer" }
const _hoisted_4 = { style: {"margin-left":"6px"} }

import { ref, reactive, onMounted, render, nextTick, watch } from 'vue'
import uuid from 'uuid'
import { useRoute, useRouter } from 'vue-router'
import ThreeScene from './components/threeScene.vue'
import BaseTab from '../Pixi/components/baseTab.vue'
import OperaTab from '../Pixi/components/OperaTab/index.vue'
import PictureTab from '../Pixi/components/pictureTab.vue'
import TextTab from '../Pixi/components/textTab.vue'
import StickersTab from '../Pixi/components/StickersTab/index.vue'
import BgTab from '../Pixi/components/BgTab/index.vue'
import TabOutside from '@/components/TabOutside/index.vue'
import LayerTab from '../Pixi/components/LayerTab/index.vue'
import DesignTab from '../Pixi/components/DesignTab/index.vue'
import ImageFillTab from '../Pixi/components/ImageFillTab/index.vue'
import ColorFillTab from '../Pixi/components/ColorFillTab/index.vue'
import ModalTab from '../Pixi/components/ModalTab/index.vue'
import CutImage from '@/components/CutImage/index.vue'
import ManySide from '../Pixi/components/ManySide/index.vue'
import ScaleFloat from '../Pixi/components/ScaleFloat/index.vue'
import DesignAreaTab from '../Pixi/components/DesignAreaTab/index.vue'
import { IAddBgType, ISpriteType, ITabType, IConfigFontStyle, IImageSprite, ISubmitParams, IParams } from '@/types'
import { dividerProps, showConfirmDialog, showDialog, Dialog, Notify, Loading, Swipe, SwipeItem } from 'vant';
import type { ICoreAppItem, ICoreAppList } from '../Pixi/types'
import Sku from '@/components/Sku/components/wu-sku/wu-sku.vue'

import { Application, Sprite } from 'pixi.js'
import Pixi from './components/core'
import { addCartItemApi, getDesignSingleApi, getProductApi, getProductSkuApi, submitDesign, updateDesign } from '@/api/common'
import { addFile } from '@/components/update'
import { generateUUID, getIntegrateUrl } from '../Pixi/components/utils'
import { IManySide, IProductInfo, RelationProduct } from '../Pixi/types'
interface IEditSprite {
  index: number;
  sprite: Sprite | null
}
const DEFAILT_CURRENT = 0

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {},
  emits: {},
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props
const emits = __emit
const route = useRoute()
const router = useRouter()

const CURRENCY_MAP = new Map([
  ['CNY', '￥'],
  ['HKD', 'HK$'],
])

const editSprite = reactive<IEditSprite>({
  index: 0,
  sprite: null
})
const tabType = ref<ITabType>('image')
const tabTypeChnagePrevType = ref<ITabType>('image')
const testIndex = ref(0)
const onTabTypeChange = (type: ITabType) => {
  if (type === 'layer') {
    if (++testIndex.value >= 10) {
      showConfirmDialog({
        title: '提示',
        message: window.location.href,
      })
      testIndex.value = 0
    }
  }
  if (type === 'modal') {
    openSku()
    return
  }
  if (!core.value) {
    return
  }
  tabType.value = type
  if (tabTypeChnagePrevType.value === type) {
    onPancelShowChange(!showPanel.value)
  } else {
    onPancelShowChange(true)
  }
  tabTypeChnagePrevType.value = type
}
const coreRef = ref<HTMLDivElement | null>(null)
const coreRefs = ref<any[]>([])
const coreAppList = ref<ICoreAppList>([])
const core = ref<any>(null)
const addCoreText = async (text: string, fontStyle: IConfigFontStyle) => {
  const sprite = await core.value?.app.addText(text, fontStyle)
  core.value?.app.historyRecord('add', [sprite])
}
const addCoreImage = (url: string) => {
  if (!url) {
    return
  }
  setNotifyLoading(true, '图片加载中')
  core.value?.app.addImage(url, {
    onCreated: (sprite: IImageSprite) => {
      core.value?.app.historyRecord('add', [sprite])
      setNotifyLoading(false)
    }
  })
}

const addCoreBg = (type: IAddBgType,url: string) => {
  core.value?.app.addBg(type, url)
}
const getCurrentSprite = () => {
  return true
}

const TYPE_TEXT_MAP = new Map([
  ['image', '照片'],
  ['text', '文字'],
  ['bg', '背景'],
  ['stickers', '贴画'],
  ['design', '设计'],
  ['layer', '图层'],
  ['imageFill', '图片填充'],
  ['colorFill', '颜色填充'],
  ['modal', '款式'],
  ['designArea', '展示方式'],
])

const panelAnimation = ref<string>('')
const showPanel = ref<boolean>(false)
const panel = ref<any>()
const transitionEnd = () => {
  if (panelAnimation.value === 'leave') {
    showPanel.value = false
  }
}
const panelTranstion = () => {
  panel.value?.addEventListener('animationend', transitionEnd)
  panel.value?.addEventListener('webkitAnimationEnd', transitionEnd)
}

const onPancelShowChange = (val: boolean, type?: ITabType) => {
  panelAnimation.value = val ? 'enter' : 'leave'
  if (type) {
    tabType.value = type
  }
  if (val) {
    showPanel.value = true
  }
}

// 操作栏
const textTab = ref<any>()
const showOpera = ref<boolean>(false)
const spriteType = ref<ISpriteType | null>(null)
const onSelectSprite = () => {
  const type = core.value?.app.target.sprite.type
  if (type === 'text') {
    // onPancelShowChange(true, 'text')
    tabTypeChnagePrevType.value = 'text'
    textTab.value?.update()
    spriteType.value = 'text'
  } else {
    onPancelShowChange(false)
    spriteType.value = 'image'
  }
  showOpera.value = true
}

// update tab data
const updateTabData = () => {
  const target = core.value?.app.target
  if (!target || !target.sprite) {
    return
  }
  const { sprite } = target
  if (sprite.type === 'text' && textTab.value) {
    textTab.value?.update()
  }
}

const onCancelSprite = () => {
  // const type = core.value?.app.target.sprite.type
  // if (type === 'text') {
  // } 
  textTab.value?.reset()
  showOpera.value = false
}
const onReset = () => {
  showConfirmDialog({
    title: '提示',
    message: '是否确认清空所有设计？',
  })
    .then(() => {
      // on confirm
      core.value?.app.reset()
    })
    .catch(() => {
      // on cancel
    });
}

const submitSuccess = () => {
  showDialog({
    title: '提示',
    message: '作品提交成功',
  }).then(() => {
    // on close
  });
}
const temImage = ref<string[]>([])
const submitSuccessVisible = ref(false)
watch(submitSuccessVisible, (val) => {
  if (!val) {
    temImage.value = []
  }
})
const submitRequest = async (params: ISubmitParams) => {
  try {
    let { design_id } = route.query
    let ajax = design_id ? updateDesign : submitDesign
    const result = await ajax(params, Number(design_id))
    return result
  } catch(err) {}
}
const onSubmit = () => {
  showConfirmDialog({
    title: '提示',
    message: '是否确认提交作品？',
  })
    .then(async () => {
      // on confirm
      setNotifyLoading(true, '作品上传中...')
      const applicationList: any[] = []
      try {
        for (let index = 0; index < coreRefs.value.length; index++) {
          const t = coreRefs.value[index];
          if (t?.isInitCompleted) {
            let params = t?.app.getParams() as IParams
            let base64 = t?.app.generateEffectImage() as string
            let effectImage = await addFile(base64)
            temImage.value.push(getIntegrateUrl(effectImage, 'tplv-z8ialgywaa-w-size:375'))
            // submitSuccessVisible.value = true
            params.effectImage = effectImage
            applicationList.push({
              ...params,
              sku_id: selectSku.value,
              product_id: productInfo.value?.productid,
              children: JSON.stringify(params.children)
            })
          } else {
            // 原数据或者为空
          }
        }
        if (applicationList.length && relationProduct.value?.p_id) {
          const result = await submitRequest({
            apps: applicationList
          })
          console.log(selectOtherSku.value)
          const addResult = await addCartItemApi({
            pcaId: localStorage.getItem('PCA_ID') || '',
            // skuId: selectSku.value,
            skuId: selectOtherSku.value.sku.id,
            productId: relationProduct.value?.p_id,
            customDesignId: result.data,
            quantity: selectOtherSku.value.num || 1,
            effectImage: applicationList.map(t => t.effectImage),
            itemName: relationProduct.value?.p_name || '',
            skuName: selectOtherSku.value.skuText.join(';'),
          })
          localStorage.setItem('PCA_ID', addResult.data.cart_id)
          router.push('/cart')
        }
        setNotifyLoading(false)
      } catch(err) {
        console.log(err)
        setNotifyLoading(false)
      }
      nextTick(() => {
        updatePreviewSwitchBtnDisabled(0)
      })
    })
    .catch(() => {
      // on cancel
    });
}

const expand = ref(false)
const onPanelExpand = () => {
  expand.value = !expand.value
}

const showNotifyLoading = ref(false)
const textNotifyLoading = ref('')
const setNotifyLoading = (val: boolean, text?: string) => {
  showNotifyLoading.value = val
  if (text) {
    textNotifyLoading.value = text
  } else {
    textNotifyLoading.value = ''
  }
}

const layerRef = ref<any>(null)
const onDelSprite = () => {
  layerRef.value?.update?.()
}
const onAddSprite = () => {
  layerRef.value?.update?.()
}

const disabledBack = ref(false)
const disabledForward = ref(true)
const setHistoryBtn = (backVisible: boolean, forwardVisible: boolean) => {
  disabledBack.value = backVisible
  disabledForward.value = forwardVisible
}
// 后退
const onHistoryBack = () => {
  core.value?.app.historyBack()
}
// 恢复
const onHistoryForward = () => {
  core.value?.app.historyForward()
}

const cutImage = reactive({
  url: '',
  onDone: (base64: string) => {}
})
const setCutImageUrl = (url: string, onDone: (base64: string) => void) => {
  cutImage.url = url
  cutImage.onDone = onDone
}

const designAreaList = ref<string[]>([])

const previewSwiperRef = ref<any>(null)
const previewPrevDiasbled = ref<boolean>(true)
const previewNextDiasbled = ref<boolean>(true)
const updatePreviewSwitchBtnDisabled = (index: number) => {
  previewPrevDiasbled.value = index === 0
  previewNextDiasbled.value = index >= temImage.value.length - 1
}
const onPreviewChange = (index: number) => {
  updatePreviewSwitchBtnDisabled(index)
}
const onPreviewSwitch = (type: 'prev' | 'next') => {
  switch(type) {
    case 'prev':
      !previewPrevDiasbled.value && previewSwiperRef.value.prev()
      break
    case 'next':
      !previewNextDiasbled.value && previewSwiperRef.value.next()
      break
  }
}

const setManySide = (index: number) => {
  const ref = coreRefs.value[index]
  let rect = coreRef.value?.getBoundingClientRect?.()
  let side = coreAppList.value[index]
  core.value = ref
  coreIndex.value = index
  designAreaList.value = side.designImage
  // 
  !core.value.app && coreRefs.value?.[index].init({
    width: rect?.width || 0,
    height: rect?.height || 0,
    ...side
  }, undefined, 'mobile', {})
  setHistoryBtn(
    ref.app.history.revokeList.length === 0,
    ref.app.history.restoreList.length === 0
  )
}

let skuShow = ref(false);
// sku列表
let skus = ref([])
const selectSku = ref('')
const selectOtherSku = ref()
const selectedSkuIndex = ref()

let skuConfirm = (e: any) => {
  let { id: sku } = e.sku
  selectOtherSku.value = e
  // console.log(e, '-e')
  const findSkuIndex = skus.value.findIndex(t => t.id === e.sku.id)
  selectedSkuIndex.value = findSkuIndex
  core.value?.app.app.destroy(true)
  loadApplication(String(sku))
  skuShow.value = false
}
const openSku = () => {
  console.log('123')
  skuShow.value = true
}

const setCoreScale = () => {
  core.value.app.setStageScale()
}
const initCore = (many_side: IManySide, design?: any) => {
  if (coreRefs.value.length) {
    let rect = coreRef.value?.getBoundingClientRect?.()
    core.value = coreRefs.value[DEFAILT_CURRENT]
    coreIndex.value = DEFAILT_CURRENT
    coreRefs.value?.[DEFAILT_CURRENT].init({
      width: rect?.width || 0,
      height: rect?.height || 0,
      ...many_side[0],
      designImage: many_side[0].designImage[0]
    }, design, 'mobile', {})
  }
}

const coreIndex = ref<number>(0)
const designInfo = ref()
const getDesignInfo = () => {
  return new Promise(async (resolve, reject) => {
    let id: any = route.query.design_id
    if (id) {
      const { data } = await getDesignSingleApi(id)
      designInfo.value = data
      resolve(data)
    }
  })
}
const productInfo = ref<IProductInfo | undefined>()
const getProductInfo = async (sku: string) => {
  try {
    const { data } = await getProductApi(sku as string)
    productInfo.value = data
    data.many_side = data.many_side.map((t: any) => ({
      ...t,
      designImage: t.designImage.split(',')
    }))
    coreAppList.value = data.many_side
    designAreaList.value = data.many_side[0].designImage
    selectSku.value = data.id
  } catch(err) {
    console.log(err)
  }
}

const relationProduct = ref<RelationProduct | undefined>()
const getProductSku = async (id: string) => {
  const { app_id, currency } = route.query
  try {
    const { data } = await getProductSkuApi(id, typeof app_id === 'string' ? app_id : '', currency || 'CNY')
    relationProduct.value = data.product
    let skuList = data.variant.map((t: any) => {
      const specLen = Object.keys(t).filter(k => /specname\d+/.test(k)).length
      let sku_attrs = {}
      for (let index = 0; index < specLen; index++) {
        let i = index + 1
        let specname = t[`specname${i}`]
        let option = t[`option${i}`]
        if (specname && option) {
          sku_attrs = {
            ...sku_attrs,
            [specname]: option
          }
        }
      }
      return {
        id: t.v_id,
        // price: t.price,
        price: Number(t.converted_price).toFixed(2),
        currency: CURRENCY_MAP.get(t.target_currency),
        stock: t.availableStockNum,
        sku_attrs,
      }
    })
    // console.log(skuList, '-skuList')
    skus.value = skuList
    
    openSku()
  } catch(err) {
    console.log(err)
  }
}

const loadApplication = async (sku: string, design_id?: string) => {
  typeof sku === 'string' && (await getProductInfo(sku))

  let many_side = productInfo.value?.many_side
  if (design_id) {
    await getDesignInfo()
    many_side && initCore(many_side, designInfo.value[DEFAILT_CURRENT])
  } else {
    many_side && initCore(many_side)
  }
}


onMounted(async () => {
  let { design_id, sku, pid, wid, app_id } = route.query
  if (!wid) {
    wid = localStorage.getItem('WID') || generateUUID()
  }
  if (pid && wid && app_id) {
    localStorage.setItem('PID', pid as string)
    localStorage.setItem('WID', wid as string)
    localStorage.setItem('APPID', app_id as string)
  } else {
    pid = localStorage.getItem('PID')
    wid = localStorage.getItem('WID')
  }
  if (!pid || !wid) {
    showDialog({
      title: '错误',
      message: '产品不存在',
    })
  }
  panelTranstion()
  if (typeof pid === 'string') {
    getProductSku(pid)
  } else {
    typeof sku === 'string' && loadApplication(sku, design_id as string | undefined)
  }
})

__expose({})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Sku, {
      modelValue: _unref(skuShow),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(skuShow) ? (skuShow).value = $event : skuShow = $event)),
      data: _unref(skus),
      themeColor: [226, 35, 26],
      defaultCover: relationProduct.value?.image_id,
      selectSkuIndex: selectedSkuIndex.value,
      btnConfirmText: "确定定制",
      notSelectSku: "请选择完整的商品信息",
      onConfirm: _unref(skuConfirm)
    }, null, 8, ["modelValue", "data", "defaultCover", "selectSkuIndex", "onConfirm"]),
    _createElementVNode("p", {
      class: "title truncate",
      onClick: _cache[1] || (_cache[1] = ($event: any) => {
      _unref(showDialog)({
        title: '产品信息',
        message: selectOtherSku.value?.skuText.join(';'),
      })
    })
    }, _toDisplayString(selectOtherSku.value ? `${relationProduct.value.p_name} / ${ selectOtherSku.value?.skuText.join(';') }` : '选择产品'), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "opera-btn",
        onClick: onHistoryBack
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["iconfont icon-shangyibu", { disabled: disabledBack.value }])
        }, null, 2)
      ]),
      _createElementVNode("div", {
        class: "opera-btn",
        onClick: onHistoryForward
      }, [
        _createElementVNode("i", {
          class: _normalizeClass(["iconfont icon-shangyibu rotate", { disabled: disabledForward.value }])
        }, null, 2)
      ]),
      _createElementVNode("div", {
        class: "opera-btn",
        onClick: onReset
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("i", { class: "iconfont icon-reset" }, null, -1)
      ])),
      _createElementVNode("div", {
        class: "opera-btn",
        onClick: onSubmit
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("button", { class: "opera-submit" }, "DONE", -1)
      ]))
    ]),
    _createElementVNode("div", {
      class: "core",
      ref_key: "coreRef",
      ref: coreRef
    }, [
      _createVNode(ThreeScene)
    ], 512),
    (coreAppList.value.length > 1)
      ? (_openBlock(), _createBlock(ManySide, {
          key: 0,
          list: coreAppList.value,
          current: coreIndex.value,
          onSetManySide: setManySide
        }, null, 8, ["list", "current"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", {
      ref_key: "panel",
      ref: panel,
      class: _normalizeClass(["panel", [panelAnimation.value]]),
      style: _normalizeStyle({ height: expand.value ? '8rem' : '' })
    }, [
      _createElementVNode("div", {
        class: "expand",
        onClick: onPanelExpand
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("span", { class: "expand-btn" }, null, -1)
      ])),
      _createVNode(TabOutside, {
        title: _unref(TYPE_TEXT_MAP).get(tabType.value),
        defaultVisible: true,
        contentHeight: "5rem",
        control: true,
        onOnChange: onPancelShowChange
      }, {
        default: _withCtx(() => [
          (tabType.value === 'image')
            ? (_openBlock(), _createBlock(PictureTab, {
                key: 0,
                onOnAdd: addCoreImage,
                onSetCutImageUrl: setCutImageUrl
              }))
            : (tabType.value === 'text')
              ? (_openBlock(), _createBlock(TextTab, {
                  key: 1,
                  ref_key: "textTab",
                  ref: textTab,
                  app: core.value.app,
                  onOnAdd: addCoreText,
                  onSetLoading: setNotifyLoading,
                  onGetCurrentSprite: getCurrentSprite
                }, null, 8, ["app"]))
              : (tabType.value === 'stickers')
                ? (_openBlock(), _createBlock(StickersTab, {
                    key: 2,
                    onOnAdd: addCoreImage
                  }))
                : (tabType.value === 'bg')
                  ? (_openBlock(), _createBlock(BgTab, {
                      key: 3,
                      app: core.value.app,
                      onOnAdd: addCoreBg
                    }, null, 8, ["app"]))
                  : (tabType.value === 'layer')
                    ? (_openBlock(), _createBlock(LayerTab, {
                        key: 4,
                        ref_key: "layerRef",
                        ref: layerRef,
                        app: core.value.app,
                        onOnAdd: addCoreBg
                      }, null, 8, ["app"]))
                    : (tabType.value === 'design')
                      ? (_openBlock(), _createBlock(DesignTab, {
                          key: 5,
                          ref: "designRef",
                          app: core.value.app,
                          productInfo: productInfo.value,
                          onOnAdd: addCoreBg,
                          onSetLoading: setNotifyLoading
                        }, null, 8, ["app", "productInfo"]))
                      : (tabType.value === 'imageFill')
                        ? (_openBlock(), _createBlock(ImageFillTab, {
                            key: 6,
                            ref: "ImageFillRef",
                            app: core.value.app,
                            onOnAdd: addCoreBg
                          }, null, 8, ["app"]))
                        : (tabType.value === 'colorFill')
                          ? (_openBlock(), _createBlock(ColorFillTab, {
                              key: 7,
                              ref: "ColorFillRef",
                              app: core.value.app,
                              onOnAdd: addCoreBg
                            }, null, 8, ["app"]))
                          : (tabType.value === 'modal')
                            ? (_openBlock(), _createBlock(ModalTab, {
                                key: 8,
                                ref: "ModalTabRef",
                                app: core.value.app,
                                onOnAdd: addCoreBg
                              }, null, 8, ["app"]))
                            : (tabType.value === 'designArea')
                              ? (_openBlock(), _createBlock(DesignAreaTab, {
                                  key: 9,
                                  ref: "DesginAreaRef",
                                  app: core.value.app,
                                  list: designAreaList.value,
                                  onSetLoading: setNotifyLoading
                                }, null, 8, ["app", "list"]))
                              : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title"])
    ], 6), [
      [_vShow, showPanel.value]
    ]),
    _createElementVNode("div", _hoisted_3, [
      (showOpera.value)
        ? (_openBlock(), _createBlock(OperaTab, {
            key: 0,
            app: core.value.app,
            spriteType: spriteType.value,
            onOnTabClick: onTabTypeChange,
            onOnClosePanel: _cache[2] || (_cache[2] = () => onPancelShowChange(false)),
            onSetCutImageUrl: setCutImageUrl
          }, null, 8, ["app", "spriteType"]))
        : (_openBlock(), _createBlock(BaseTab, {
            key: 1,
            onOnTabClick: onTabTypeChange
          }))
    ]),
    _createVNode(_unref(Notify), {
      show: showNotifyLoading.value,
      "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((showNotifyLoading).value = $event)),
      type: "success"
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Loading), {
          color: "#ffffff",
          size: "24"
        }),
        _createElementVNode("span", _hoisted_4, _toDisplayString(textNotifyLoading.value), 1)
      ]),
      _: 1
    }, 8, ["show"]),
    (cutImage.url)
      ? (_openBlock(), _createBlock(CutImage, {
          key: 1,
          value: cutImage.url,
          onOnCutDone: cutImage.onDone
        }, null, 8, ["value", "onOnCutDone"]))
      : _createCommentVNode("", true),
    _createVNode(ScaleFloat, { onSetCoreScale: setCoreScale }),
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "not-render" }, [
      _createElementVNode("a", {
        href: "#1",
        id: "not-link"
      })
    ], -1))
  ]))
}
}

})